"use client";

import { usePathname } from "next/navigation";

/* eslint-disable react/jsx-no-literals */
export const DraftModeNotice = () => {
	const pathname = usePathname();

	return (
		<div className="fixed bottom-0 z-50 w-full bg-orange-400 p-4 text-center text-base font-bold text-black">
			You are looking at the unpublished version of this page.{` `}
			<a
				href={`/api/webhooks/cms/draft/disable?redirectTo=${encodeURIComponent(pathname)}`}
				className="underline"
			>
				Exit
			</a>
		</div>
	);
};
