// This needs to be a client component because of Chakra UI (emotion)
"use client";

import { CacheProvider } from "@chakra-ui/next-js";
import { ChakraProvider } from "@chakra-ui/react";
import { SiteLocale } from "src/__generated__/graphql";
import { IubendaProvider } from "src/contexts/IubendaContext";
import iubendaConfiguration from "src/lib/iubendaConfiguration";
import { GlobalStyles } from "src/styles/GlobalStyles";
import theme from "src/styles/theme";

export const Providers = ({
	children,
	locale,
}: {
	children: React.ReactNode;
	locale: SiteLocale;
}) => {
	return (
		<IubendaProvider bannerConfig={iubendaConfiguration(locale)}>
			<CacheProvider>
				<ChakraProvider theme={theme}>
					{children}
					<GlobalStyles />
				</ChakraProvider>
			</CacheProvider>
		</IubendaProvider>
	);
};
